import { Dimension } from "dimtext";
import {
  sortableList,
  sortArrayBy,
} from "@local/extensions/collections/sortable-list.js";
import { hydrateType } from "./type.js";
import { hydrateItem } from "./item.js";

import type { DimTextOptions } from "dimtext";
import type { PartialDeep } from "type-fest";
import type { OrderedMap } from "../ordered-map.js";
import type { CustomColumns } from "./column.js";
import type { Fabrication } from "./fabrication.js";

interface GroupRecordCore {
  data: {
    custom_collection_columns?: object;
    column_order?: object;
    item_order?: object;
    collection_column_order?: object;
    custom_columns?: CustomColumns;
    type_order: Array<string>;
    fabrications: OrderedMap<string, Fabrication>;
    settings: object;
    documents: object;
  };
}

interface GroupRecordComputed {
  dimtext_options?: PartialDeep<DimTextOptions>;
}

function createGroup(
  organization_id: string,
  job_id: string,
  {
    data: {
      settings = {},
      documents = {
        indices: {},
        order: [],
      },
      fabrications = {
        indices: {},
        order: [],
      },
    } = {},
    ...props
  } = {},
) {
  return {
    id: crypto.randomUUID(),
    organization_id,
    job_id,
    seller_reference: null,
    buyer_reference: null,
    project_type: "unconstrained",
    data: {
      settings: {
        dimension_format: "FRACTIONAL",
        decimal_precision: 2,
        fractional_precision: 4,
        mm_precision: 0,
        display_unit: "inches",
        label_by: "mark",
        collections_label_by: "mark",
        document_display_mode: "view",
        document_sort_by: "created_at",
        hide_width_offset: true,
        hide_height_offset: true,
        summary_default_type_view: -1,
        summary_show_untyped_items: true,
        ...settings,
      },
      documents,
      fabrications,
    },
    ...props,
  };
}

function hydrateGroupData(data: GroupRecordCore["data"]) {
  data.fabrications?.order.forEach((id) => {
    const fab = data.fabrications[id];
    if (fab.params) {
      fab.params = fab.params.map((p) => {
        return new Dimension(p);
      });
    }
  });
}

function hydrateGroup<G extends GroupRecordCore>(group: G) {
  hydrateGroupData(group.data);

  const g = { ...group };

  g.types = sortableList(
    sortArrayBy(
      group.types.map((t) => hydrateType(t)),
      group.data.type_order,
    ),
  );

  g.items = sortableList(
    sortArrayBy(
      group.items.map((i) => hydrateItem(i, g)),
      group.data.item_order,
    ),
  );

  return g;
}

export type { GroupRecordCore, GroupRecordComputed };

export { createGroup, hydrateGroup, hydrateGroupData };
