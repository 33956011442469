<script context="module">
  /** @type {Map<string, Promise<Blob>>} */
  const _blob_cache = new Map();

  /*
    Not currently in use

    NOTE: Before enabling, ensure that error on rejected getBlob is properly handled.
    If enabled as-is, an error will throw at small breakpoints due to unhandled
    promise rejection.
  */
  let cacheEnabled = false;

  export const cache = {
    /**
     * @param {string} id
     * @returns {Promise<Blob>}
     */
    get(id) {
      return _blob_cache.get(id);
    },

    /**
     * @param {string} id
     */
    has(id) {
      return _blob_cache.has(id);
    },

    clear() {
      _blob_cache.clear();
    },
  };
</script>

<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { dist, subtract, scale, add, multiply } from "vector";
  import { getZoomscale } from "overline/geometry";
  import { setCanvasCtx } from "src/extensions/canvas-viewport";
  import { draggable } from "svelte-utilities";
  import { currentTool } from "../../stores/ui.js";
  import eb from "../../extensions/event-bus.js";
  import nearColors from "../../extensions/near-colors.js";

  export let drawing;
  export let hitboxDrawing = null;
  export let padding = 100;
  export let paddingTop = padding;
  export let paddingRight = padding;
  export let paddingBottom = padding;
  export let paddingLeft = padding;
  export let pannable = false;
  export let zoomable = false;
  export let autofit = true;
  export let key = null;
  export let dimension = null;
  export let cacheKey = null;

  const dpi = window.devicePixelRatio;
  const dispatch = createEventDispatcher();

  /** @type {Element} */
  let container;

  /** @type {HTMLCanvasElement}*/
  let canvas;

  /** @type {HTMLCanvasElement}*/
  let hitCanvas;

  let offsetWidth;
  let offsetHeight;
  let cw;
  let ch;
  let ctx;
  let hitCtx;
  let hitHash;
  let reverseHitHash;
  let bbox;
  let tempTool = null;
  let panning = false;
  let dragging = null;
  let currentMouseover = null;
  let multitouchDragging = null;
  let touchPanning = null;

  // variables to detect what has changed
  let prevkey = key;
  let prevP = padding;
  let prevPt = paddingTop;
  let prevPr = paddingRight;
  let prevPb = paddingBottom;
  let prevPl = paddingLeft;
  let prevCw = cw;
  let prevCh = ch;

  $: extents = drawing.bbox;
  $: {
    if (ctx && drawing && bbox) {
      if (
        autofit ||
        keyChanged(key) ||
        paddingChanged(padding, paddingTop, paddingRight, paddingBottom, paddingLeft)
      ) {
        fit();
      }

      render();
      cacheBlob();
    }
  }

  function keyChanged(key) {
    const v = key !== prevkey;
    prevkey = key;
    return v;
  }

  function paddingChanged(p, pt, pr, pb, pl) {
    let v = false;

    if (p !== prevP) {
      v = true;
      prevP = p;
    }

    if (pt !== prevPt) {
      v = true;
      prevPt = pt;
    }

    if (pr !== prevPr) {
      v = true;
      prevPr = pr;
    }

    if (pb !== prevPb) {
      v = true;
      prevPb = pb;
    }

    if (pl !== prevPl) {
      v = true;
      prevPl = pl;
    }

    return v;
  }

  function sizeChanged(cw, ch) {
    let v = false;

    if (cw !== prevCw) {
      v = true;
      prevCw = cw;
    }

    if (ch !== prevCh) {
      v = true;
      prevCh = ch;
    }

    return v;
  }

  function fit() {
    const cWidth = Math.ceil(cw * dpi);
    const cHeight = Math.ceil(ch * dpi);
    bbox = getZoomscale(
      cWidth,
      cHeight,
      extents,
      paddingTop * dpi,
      paddingRight * dpi,
      paddingBottom * dpi,
      paddingLeft * dpi,
    );
  }

  function convertPtToScreen({ x, y }) {
    const scale = bbox.zoomscale / dpi;
    return {
      x: (x - bbox.x) * scale,
      y: -(y - bbox.y) * scale,
    };
  }

  function convertPtFromScreen({ x, y }) {
    const scale = bbox.zoomscale / dpi;
    return {
      x: x / scale + bbox.x,
      y: -(y / scale) + bbox.y,
    };
  }

  function render() {
    canvas.style.width = `${cw}px`;
    canvas.style.height = `${ch}px`;
    canvas.width = Math.ceil(cw * dpi);
    canvas.height = Math.ceil(ch * dpi);
    setCanvasCtx(ctx, bbox.zoomscale, bbox.x, bbox.y);

    drawing.render({
      ctx,
      annoScale: dpi / bbox.zoomscale,
    });

    if (hitboxDrawing) {
      hitCanvas.style.width = `${cw}px`;
      hitCanvas.style.height = `${ch}px`;
      hitCanvas.width = Math.ceil(cw * dpi);
      hitCanvas.height = Math.ceil(ch * dpi);
      setCanvasCtx(hitCtx, bbox.zoomscale, bbox.x, bbox.y);

      hitHash = hitboxDrawing.renderHitbox({
        ctx: hitCtx,
        annoScale: dpi / bbox.zoomscale,
      });

      // The below is something of a hack--because zoom/pan requires updating
      // the position of the dimension, we are coupling the Viewport component
      // with the optional "dimension" property.
      reverseHitHash = Object.entries(hitHash).reduce((h, [color, obj]) => {
        h[obj.name] = color;
        return h;
      }, {});

      if (dimension) {
        const dimColor = reverseHitHash[dimension.hitbox];
        const hb = hitHash[dimColor];

        if (hb && hb.pt) {
          dimension = {
            ...dimension,
            pt: convertPtToScreen(hb.pt),
          };
        }
      }
    }
  }

  /**
   * @param {string} type
   * @param {number=} quality
   * @returns {Promise<Blob>}
   */
  async function getBlob(type = "image/png", quality) {
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(null); // Right thing to do?
          }
        },
        type,
        quality,
      );
    });
  }

  function cacheBlob() {
    if (cacheEnabled && cacheKey) {
      _blob_cache.set(cacheKey, getBlob());
    }
  }

  function getEventObj(e) {
    const rect = e.target.getBoundingClientRect();
    let x;
    let y;
    if (e.touches?.length > 0) {
      x = (e.touches[0].clientX - rect.left) * dpi;
      y = (e.touches[0].clientY - rect.top) * dpi;
    } else {
      x = (e.clientX - rect.left) * dpi;
      y = (e.clientY - rect.top) * dpi;
    }

    const p = hitCtx.getImageData(x, y, 1, 1).data;

    if (p[0] === 0 && p[1] === 0 && p[2] === 0) {
      return null;
    }

    const color = `rgb(${p[0]},${p[1]},${p[2]})`;
    if (hitHash[color]) return hitHash[color];

    // we have a color that isn't a precise match :(
    // Check to see whether there is a near-ish color?
    const near = nearColors(p[0], p[1], p[2], 1);
    const match = near.find((c) => hitHash[c]);
    if (match) return hitHash[match];

    return null;
  }

  function evtProps(p) {
    const rect = canvas.getBoundingClientRect();
    const screenPt = { x: p.x, y: p.y };
    const pt = { x: p.x - rect.left, y: p.y - rect.top };
    const scale = bbox.zoomscale / dpi;
    const dwgPt = convertPtFromScreen(pt);

    return { screenPt, scale, dwgPt };
  }

  function touchEventProps(e) {
    const rect = e.target.getBoundingClientRect();
    let screenPt;
    let pt;
    if (e.type === "touchend") {
      screenPt = { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY };
      pt = { x: e.changedTouches[0].clientX - rect.left, y: e.changedTouches[0].clientY - rect.top };
    } else {
      screenPt = { x: e.touches[0].clientX, y: e.touches[0].clientY };
      pt = { x: e.touches[0].clientX - rect.left, y: e.touches[0].clientY - rect.top };
    }
    const scale = bbox.zoomscale / dpi;
    const dwgPt = convertPtFromScreen(pt);

    return { screenPt, scale, dwgPt };
  }

  function dragstart(e) {
    // Are we panning?
    if (e.detail.button === 2 || e.detail.button === 1) {
      tempTool = $currentTool;
      $currentTool = "pan";
      panning = true;
      return;
    } else if ($currentTool === "pan") {
      panning = true;
      return;
    }

    // Otherwise, check for dragged hitbox
    if (!hitboxDrawing) return;
    const hitbox = getEventObj(e.detail.event);
    if (!hitbox) return;
    dragging = hitbox;

    const props = evtProps(e.detail);

    dispatch("vpdragstart", {
      ...props,
      hitbox,
      shiftKey: e.detail.event.shiftKey,
      metaKey: e.detail.event.metaKey,
    });
  }

  function pan(e) {
    const { dx: dxPx, dy: dyPx } = e.detail;

    const dx = (dxPx * dpi) / bbox.zoomscale;
    const dy = (dyPx * dpi) / bbox.zoomscale;

    bbox = {
      ...bbox,
      x: bbox.x - dx,
      y: bbox.y + dy,
    };
  }

  function touchPan(e) {
    const { prev, end } = e;

    const dx = ((end.x - prev.x) * dpi) / bbox.zoomscale;
    const dy = ((end.y - prev.y) * dpi) / bbox.zoomscale;

    bbox = {
      ...bbox,
      x: bbox.x - dx,
      y: bbox.y + dy,
    };
  }

  function drag(e) {
    if (panning) return pan(e);

    // Handle hitbox dragging
    if (!dragging || !hitboxDrawing) return;

    const props = evtProps(e.detail);

    dispatch("vpdrag", {
      ...props,
      hitbox: dragging,
      shiftKey: e.detail.event.shiftKey,
      metaKey: e.detail.event.metaKey,
    });
  }

  function dragend(e) {
    if (panning) {
      pan(e);
      if (tempTool) {
        $currentTool = tempTool;
        tempTool = null;
      }
      panning = false;
      dragging = null;
      return;
    }

    if (!dragging || !hitboxDrawing) return;

    const props = evtProps(e.detail);

    dispatch("vpdragend", {
      ...props,
      hitbox: dragging,
      shiftKey: e.detail.event.shiftKey,
      metaKey: e.detail.event.metaKey,
    });

    dragging = null;
  }

  function scroll(e) {
    e.preventDefault();
    if (!zoomable || panning) return;

    const rect = e.target.getBoundingClientRect();
    const cx = e.clientX - rect.left;
    const cy = e.clientY - rect.top;
    const zoomscale = e.deltaY < 0 ? bbox.zoomscale * 1.1 : bbox.zoomscale * (1 / 1.1);

    // after zooming, keep the position under mouse the same
    const mx = (cx * dpi) / bbox.zoomscale + bbox.x;
    const my = -(cy * dpi) / bbox.zoomscale + bbox.y;

    zoom(zoomscale, mx, my);
  }

  function zoom(zoomscale, px, py) {
    const z = zoomscale / bbox.zoomscale;

    const width = bbox.width / z;
    const height = bbox.height / z;

    const x = px - (px - bbox.x) / z;
    const y = py - (py - bbox.y) / z;

    bbox = { width, height, zoomscale, x, y };
  }

  function zoomIn() {
    const x = extents.xmin + (extents.xmax - extents.xmin) / 2;
    const y = extents.ymin + (extents.ymax - extents.ymin) / 2;
    zoom(1.3 * bbox.zoomscale, x, y);
  }

  function zoomOut() {
    const x = extents.xmin + (extents.xmax - extents.xmin) / 2;
    const y = extents.ymin + (extents.ymax - extents.ymin) / 2;
    zoom(bbox.zoomscale / 1.3, x, y);
  }

  function zoomFit() {
    fit();
  }

  function click(e) {
    if (!hitboxDrawing || $currentTool === "pan") return;
    const hitbox = getEventObj(e);
    let dim;
    if (hitbox && hitbox.name && hitbox.name.match(/^(dim|dtdim|dimonly)_/)) {
      const d = drawing.find(hitbox.name);
      if (d) dim = d.entity;
    }

    dispatch("vpclick", {
      hitbox,
      dim,
      shiftKey: e.shiftKey,
      metaKey: e.metaKey,
      ...(hitbox && hitbox.pt && { pt: convertPtToScreen(hitbox.pt) }),
    });
  }

  function mousedown(e) {
    if (!hitboxDrawing) return;
    const hitbox = getEventObj(e);

    dispatch("vpmousedown", {
      hitbox,
      shiftKey: e.shiftKey,
      metaKey: e.metaKey,
      ...(hitbox && hitbox.pt && { pt: convertPtToScreen(hitbox.pt) }),
    });
  }

  function isNewHitbox(current, hb) {
    if (!current) return false;
    if (!hb) return true;
    if (currentMouseover.name === hb.name) {
      return false;
    }
    return true;
  }

  function mousemove(e) {
    if (!hitboxDrawing) return;
    if (dragging) return;
    const hitbox = getEventObj(e);

    const pt = { x: e.clientX, y: e.clientY };
    const props = evtProps(pt);

    if (isNewHitbox(currentMouseover, hitbox)) {
      dispatch("vpmouseout", {
        ...props,
        hitbox: currentMouseover,
        shiftKey: e.shiftKey,
        metaKey: e.metaKey,
      });
    }

    if (hitbox && (!currentMouseover || hitbox.name !== currentMouseover.name)) {
      dispatch("vpmouseover", {
        ...props,
        hitbox,
        shiftKey: e.shiftKey,
        metaKey: e.metaKey,
      });
    } else if (!hitbox || (currentMouseover && hitbox.name === currentMouseover.name)) {
      dispatch("vpmousemove", {
        ...props,
        hitbox,
        shiftKey: e.shiftKey,
        metaKey: e.metaKey,
      });
    }

    currentMouseover = hitbox;
  }

  function mouseout(e) {
    if (!hitboxDrawing) return;

    const pt = { x: e.clientX, y: e.clientY };
    const props = evtProps(pt);

    if (currentMouseover) {
      dispatch("vpmouseout", {
        ...props,
        hitbox: currentMouseover,
        shiftKey: e.shiftKey,
        metaKey: e.metaKey,
      });
      currentMouseover = null;
    }
  }

  function onResize(e) {
    const cr = e?.[0]?.contentRect;
    if (cr) {
      cw = cr.width;
      ch = cr.height;
    }

    const sr = sizeChanged(cw, ch);

    if (autofit || sr) {
      fit();
    }

    render();
    cacheBlob();
  }

  function touchstart(e) {
    if (e.touches.length === 2 && (zoomable || pannable)) {
      const rect = e.target.getBoundingClientRect();
      const a = { x: e.touches[0].clientX - rect.left, y: e.touches[0].clientY - rect.top };
      const b = { x: e.touches[1].clientX - rect.left, y: e.touches[1].clientY - rect.top };
      const d = dist(a, b);
      const pt = add(a, scale(subtract(b, a), 0.5));

      multitouchDragging = {
        initialZoom: bbox.zoomscale,
        initialBboxPt: { x: bbox.x, y: bbox.y },
        initialWd: bbox.width,
        initialHt: bbox.height,
        initialDist: d,
        lastDist: d,
        initialPt: pt,
        pt: convertPtFromScreen(pt),
        translation: 0,
      };
    } else if (e.touches.length === 1) {
      // Are we panning?
      if ($currentTool === "pan") {
        const rect = e.target.getBoundingClientRect();
        const a = { x: e.touches[0].clientX - rect.left, y: e.touches[0].clientY - rect.top };
        panning = true;
        touchPanning = {
          start: a,
          prev: a,
          end: a,
        };
        return;
      }

      // Otherwise, check for dragged hitbox
      if (!hitboxDrawing) return;
      const hitbox = getEventObj(e);
      if (!hitbox) return;
      dragging = hitbox;
      const props = touchEventProps(e);

      dispatch("vpdragstart", {
        ...props,
        hitbox,
        shiftKey: false,
        metaKey: false,
      });
    }
  }

  function touchmove(e) {
    e.preventDefault();
    if (multitouchDragging) {
      if (e.touches.length === 2) {
        const rect = e.target.getBoundingClientRect();
        const a = { x: e.touches[0].clientX - rect.left, y: e.touches[0].clientY - rect.top };
        const b = { x: e.touches[1].clientX - rect.left, y: e.touches[1].clientY - rect.top };
        const d = dist(a, b);
        const pt = add(a, scale(subtract(b, a), 0.5));

        multitouchDragging.lastDist = d;
        multitouchDragging.translation = subtract(pt, multitouchDragging.initialPt);
      }

      const md = multitouchDragging;
      const zs = md.lastDist / md.initialDist;

      // compute new bbox
      const zoomscale = md.initialZoom * zs;
      const z = zoomscale / md.initialZoom;
      const width = md.initialWd / z;
      const height = md.initialHt / z;

      const t0 = scale(md.translation, dpi / md.initialZoom);
      const pt0 = {
        x: md.initialBboxPt.x - t0.x,
        y: md.initialBboxPt.y + t0.y,
      };

      const x = md.pt.x - (md.pt.x - pt0.x) / z;
      const y = md.pt.y - (md.pt.y - pt0.y) / z;

      bbox = { x, y, zoomscale, width, height };
    } else if (e.touches.length === 1) {
      if (panning) {
        const rect = e.target.getBoundingClientRect();
        const a = { x: e.touches[0].clientX - rect.left, y: e.touches[0].clientY - rect.top };
        touchPanning.end = a;
        touchPan(touchPanning);
        touchPanning.prev = a;
        return;
      }

      if (!dragging || !hitboxDrawing) return;
      const props = touchEventProps(e);

      dispatch("vpdrag", {
        ...props,
        hitbox: dragging,
        shiftKey: false,
        metaKey: false,
      });
    }
  }

  function touchend(e) {
    if (e.changedTouches?.length === 1 && e.touches.length === 0) {
      if (panning) {
        const rect = e.target.getBoundingClientRect();
        const a = {
          x: e.changedTouches?.[0].clientX - rect.left,
          y: e.changedTouches?.[0].clientY - rect.top,
        };
        touchPanning.end = a;
        touchPan(touchPanning);
        touchPanning.prev = a;
        panning = false;
        dragging = null;
        multitouchDragging = null;
        return;
      }

      if (!dragging || !hitboxDrawing) {
        multitouchDragging = null;
        return;
      }

      const props = touchEventProps(e);

      dispatch("vpdragend", {
        ...props,
        hitbox: dragging,
        shiftKey: false,
        metaKey: false,
      });
    }

    multitouchDragging = null;
    dragging = null;
    panning = false;
  }

  onMount(() => {
    cw = offsetWidth;
    cw = offsetHeight;
    canvas.width = cw;
    canvas.height = ch;
    ctx = canvas.getContext("2d");
    fit();

    const resize = new ResizeObserver(onResize);
    resize.observe(container);

    if (zoomable) {
      eb.on("zoom-in", zoomIn);
      eb.on("zoom-out", zoomOut);
      eb.on("zoom-to-fit", zoomFit);
    }

    if (hitboxDrawing) {
      hitCanvas = document.createElement("canvas");
      hitCtx = hitCanvas.getContext("2d");
      hitCtx.imageSmoothingEnabled = false;
    }

    return () => {
      resize.disconnect();

      if (zoomable) {
        eb.unsubscribe("zoom-in", zoomIn);
        eb.unsubscribe("zoom-out", zoomOut);
        eb.unsubscribe("zoom-to-fit", zoomFit);
      }
    };
  });
</script>

<div class="w-full h-full overflow-hidden absolute" bind:this={container} bind:offsetWidth bind:offsetHeight>
  <canvas
    class:cursor-grab={$currentTool === "pan" && !panning}
    class:cursor-grabbing={panning}
    use:draggable={{ use: pannable }}
    bind:this={canvas}
    on:contextmenu|preventDefault
    on:dragstart={dragstart}
    on:drag={drag}
    on:dragend={dragend}
    on:touchstart={touchstart}
    on:touchmove={touchmove}
    on:touchend={touchend}
    on:wheel={scroll}
    on:click={click}
    on:mousedown={mousedown}
    on:mousemove={mousemove}
    on:mouseout={mouseout}
    on:blur={mouseout} />
</div>
