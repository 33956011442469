import { tick } from "svelte";
import { writable } from "svelte/store";
import { navigate } from "svelte-routing";
import { StatusCodes as H } from "http-status-codes";
import { api, signIn, getPasswordResetLink } from "../api";
import { getLogger } from "@diagraphics/logging";
import { DEFAULT_LOG_LEVEL } from "src/env.js";
import getUrlParameterByName from "src/extensions/dom/get-url-parameter-by-name.js";

/** @typedef {import('@supabase/supabase-js').User} User */

/**
 * @template T
 * @typedef {import('svelte/store').Writable<T>} Writable<T>
 */

const log = getLogger(Symbol("stores/auth"), DEFAULT_LOG_LEVEL, true);

export const loading = writable(false);
export const apiError = writable(null);

// If there is an error in the URL hash, strip it from the URL (to avoid
// auto-logout by the supabase client).
const errorParameter = getUrlParameterByName("error_description");
if (errorParameter) {
  const url = new URL(location.href);
  const parsedHash = new URLSearchParams(url.hash.slice(1));
  const desc = parsedHash.get("error_description");
  apiError.set(desc);
  url.hash = "";
  history.replaceState(null, null, url);
}

function createProfile() {
  const { subscribe, set, update } = writable(null);

  return {
    subscribe,
    set,
    update,
    async fetch(id) {
      const { data, error } = await api
        .from("profiles")
        .select(
          "*, organization: organization_id(*, locator), feature_flags(key,value)",
        )
        .eq("id", id)
        .single();
      if (error) throw error;

      if (data.user_role === "developer") {
        const currentOrg = localStorage.getItem("currentOrg");
        if (currentOrg) {
          const { data: org, error: orgError } = await api
            .from("organizations")
            .select("*, locator")
            .eq("id", currentOrg)
            .single();

          if (org) {
            data.organization_id = currentOrg;
            data.organization = org;
          } else {
            localStorage.removeItem("currentOrg");
          }
        }
      }

      // log.debug("[stores/auth] Setting profile data %o", data);
      set(data);
      return data;
    },

    async onboard() {
      let id;

      update((p) => {
        if (!p) return p;
        id = p.id;
        return { ...p, has_onboarded: true };
      });

      if (!id) return;

      await api.from("profiles").update({ has_onboarded: true }).eq("id", id);
      localStorage.setItem("login-pref", "password");
    },
  };
}

export const profile = createProfile();

function createShare() {
  const { subscribe, set } = writable(null);

  return {
    subscribe,
    set,
    async fetch(user_id, group_id) {
      try {
        const { data, error } = await api
          .from("shares")
          .select("*")
          .eq("user_id", user_id)
          .eq("group_id", group_id)
          .single();

        if (error) throw error;

        set(data);
      } catch (error) {
        log.error(error);
      }
    },
  };
}

export const share = createShare();

function createUser() {
  /** @type {Writable<User | null>} */
  const { subscribe, set } = writable(null);

  api.auth.onAuthStateChange((event, session) => {
    try {
      if (event === "SIGNED_IN" && session) {
        // log.debug(
        //   "[stores/auth] Auth state event SIGNED_IN with session %o",
        //   session
        // );

        set(session.user);
      } else if (event === "SIGNED_OUT") {
        // log.debug("[stores/auth] Auth state event SIGNED_OUT");
        set(null);
        profile.set(null);
      }
    } catch (error) {
      log.error(
        "[stores/auth] Error in auth state change handler for event %s: %o",
        event,
        error,
      );
      localStorage.removeItem(api.auth.storageKey);
      profile.set(null);
      set(null);
    }
  });

  const sendMagicLink = async (email, path = "") => {
    const redirectTo = window.location.origin + "/" + path;

    loading.set(true);
    apiError.set(null);

    try {
      const { error } = await signIn({ email, redirectTo });
      if (error) throw error;
      set(null);
    } catch (error) {
      if (error.status === H.FORBIDDEN) {
        apiError.set(`Sorry, we couldn't find a user account for ${email}`);
      } else if (error.status === H.TOO_MANY_REQUESTS) {
        apiError.set(error.message);
      } else {
        apiError.set("Sorry, this user could not be logged in.");
        log.error(error);
      }
      loading.set(false);
    } finally {
      loading.set(false);
    }
  };

  const verifyOtp = async (email, otp) => {
    loading.set(true);
    apiError.set(null);

    try {
      const { data, error } = await api.auth.verifyOtp({
        email,
        token: otp,
        type: "magiclink",
      });
      if (error) throw error;

      const user = data.user;
      if (!user) throw { message: "Not a valid user" };

      const p = await profile.fetch(user.id);
      if (p?.disabled_at)
        throw { message: "Sorry, this user could not be logged in." };

      set(user);
      return p;
    } catch (error) {
      apiError.set(error.message);
      set(null);
      loading.set(false);
    } finally {
      loading.set(false);
    }
  };

  const login = async (email, password, path = "") => {
    const redirectTo = window.location.origin + "/" + path;

    loading.set(true);
    apiError.set(null);

    try {
      const { data, error } = await api.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;

      const user = data.user;
      if (!user) throw { message: "Not a valid user" };

      const p = await profile.fetch(user.id);
      if (p?.disabled_at)
        throw { message: "Sorry, this user could not be logged in." };

      set(user);
    } catch (error) {
      apiError.set(error.message);
      set(null);
      loading.set(false);
    } finally {
      loading.set(false);
    }
  };

  const logout = async () => {
    loading.set(true);

    try {
      const { error } = await api.auth.signOut();
      if (error) throw error;

      const channelStatus = await api.removeAllChannels();

      if (channelStatus.some((res) => res != "ok")) {
        log.warn(
          "[stores/auth] One or more realtime channels returned a non-ok status when attempting to unsubscribe.",
        );
      } else {
        log.info(
          "[stores/auth] Successfully unsubscribed from all realtime channels.",
        );
      }

      set(null);
      profile.set(null);
      await tick();
      navigate("/login");
    } catch (error) {
      log.error(error);
    } finally {
      loading.set(false);
      localStorage.removeItem(api.auth.storageKey);
    }
  };

  const resetPassword = async (email, path = "") => {
    const redirectTo = window.location.origin + "/" + path;

    loading.set(true);
    apiError.set(null);

    try {
      const { error } = await getPasswordResetLink({ email, redirectTo });
      if (error) throw error;
    } catch (error) {
      apiError.set(error.message);
      set(null);
    } finally {
      loading.set(false);
    }
  };

  const updatePassword = async (password) => {
    try {
      const { data, error } = await api.auth.updateUser({ password });
      if (error) throw error;
      set(data?.user);
      return { user };
    } catch (error) {
      return { error };
    }
  };

  const getSession = async () => {
    const { data, error } = await api.auth.getSession();

    if (data?.session?.user) {
      log.debug("[stores/auth] Setting user data %o", data.session.user);
      user.set(data.session.user);

      try {
        const p = await profile.fetch(data.session.user.id);
        if (p?.disabled_at) {
          user.logout();
          return;
        }

        if (p?.has_onboarded === false) {
          await profile.onboard();
          navigate("/passreset");
        }
      } catch (e) {
        user.logout();
      }
    } else {
      if (error) {
        log.error("[stores/auth] Error getting session %o", error);
      } else if (!data?.session) {
        log.debug("[stores/auth] No session active");
      } else {
        log.debug("[stores/auth] Session w/o user?", data.session);
      }
      user.set(null);
      profile.set(null);
    }
  };

  return {
    set,
    subscribe,
    sendMagicLink,
    resetPassword,
    updatePassword,
    getSession,
    verifyOtp,
    login,
    logout,
  };
}

export const user = createUser();
