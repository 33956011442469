import { Point } from "./point.js";

// https://nodejs.org/api/util.html#utilinspectcustom
const inspect = Symbol.for("nodejs.util.inspect.custom");

const pxy = new Proxy([], {
  get(target, prop, receiver) {
    console.log("prop = ", prop);
    console.log("typeof prop =", typeof prop);
  },
});

/**
 * @param {number} cWidth Content width
 * @param {number} cHeight Content height
 * @param {{ xmax: number; xmin: number; ymax: number; ymin: number; }} bbox
 */
function getZoomscale(
  cWidth,
  cHeight,
  bbox,
  paddingTop = 100,
  paddingRight = paddingTop,
  paddingBottom = paddingTop,
  paddingLeft = paddingTop
) {
  const bwidth = bbox.xmax - bbox.xmin;
  const bheight = bbox.ymax - bbox.ymin;

  const bar = bwidth / bheight;
  const vw = cWidth - paddingRight - paddingLeft;
  const vh = cHeight - paddingTop - paddingBottom;
  const vpar = vw / vh;

  const paddingPct =
    bar > vpar
      ? (paddingLeft + paddingRight) / cWidth
      : (paddingTop + paddingBottom) / cHeight;

  const zoomscale =
    bar > vpar
      ? (cWidth / bwidth) * (1 - paddingPct)
      : (cHeight / bheight) * (1 - paddingPct);

  const width = cWidth / zoomscale;
  const height = cHeight / zoomscale;

  const scaledvw = vw / zoomscale;
  const scaledvh = vh / zoomscale;

  const scaledpl = paddingLeft / zoomscale;
  const scaledpt = paddingTop / zoomscale;

  const center = {
    x: bbox.xmin + bwidth / 2,
    y: bbox.ymin + bheight / 2,
  };

  const x = center.x - scaledvw / 2 - scaledpl;
  const y = center.y + scaledvh / 2 + scaledpt;

  return { zoomscale, x, y, width, height };
}

class Box {
  constructor(...args) {
    let xmin, ymin, xmax, ymax;
    switch (args.length) {
      case 2:
        [[xmin, ymin], [xmax, ymax]] = args;
        break;
      case 4:
        [xmin, ymin, xmax, ymax] = args;
        break;
      default:
        throw new Error();
    }

    /** @type { number } */
    this.xmin = xmin;

    /** @type { number } */
    this.ymin = ymin;

    /** @type { number } */
    this.xmax = xmax;

    /** @type { number } */
    this.ymax = ymax;
  }

  /**
   * @param {Box} container
   * @param {Box} contained
   */
  static contain(container, contained) {
    const { center, aspectRatio: ar1 } = container;
    const { aspectRatio: ar2 } = contained;

    let width, height;

    if (ar2 < ar1) { // contained taller than container
      height = container.height;
      width = height * ar2;
    } else {
      width = container.width;
      height = width / ar2;
    }

    return Box.construct({ center, width, height });
  }

  /**
   * @overload
   * @param {object} params
   * @param { Array | Point } params.center
   * @param {number} params.width
   * @param {number} params.height
   * @returns {Box}
   */

  /**
   * @overload
   * @param {object} params
   * @param {number} [params.x]
   * @param {number} [params.y]
   * @param {number} params.width
   * @param {number} params.height
   * @returns {Box}
   */

  /**
   * @param {object} params
   * @returns {Box}
   */
  static construct(params) {
    const { center, width, height, x = 0, y = 0 } = params;

    if (center && width && height) {
      const [x0, y0] = center;
      const dx = width / 2;
      const dy = height / 2;
      return new Box(x0 - dx, y0 - dy, x0 + dx, y0 + dy);
    } else if (width && height) {
      return new Box(x, y, x + width, y + height);
    } else {
      throw new Error(
        `Construction with only the following parameters is not supported: ${Object.keys(
          params
        ).join(", ")}`
      );
    }
  }

  valueOf() {
    return this.toString();
  }

  toString() {
    return `[(${this.xmin}, ${this.ymin}), (${this.xmax}, ${this.ymax})]`;
  }

  [inspect]() {
    return `Box ${this.toString()}`;
  }

  get x() {
    return this.xmin;
  }

  get y() {
    return this.ymin;
  }

  get width() {
    return this.xmax - this.xmin;
  }

  get height() {
    return this.ymax - this.ymin;
  }

  get aspectRatio() {
    return this.width / this.height;
  }

  get top() {
    return this.ymin;
  }

  get bottom() {
    return this.ymax;
  }

  get left() {
    return this.xmin;
  }

  get right() {
    return this.xmax;
  }
  /* Swizzles */

  /**
   * @returns {[number, number, number, number]}
   */
  get xywh() {
    return [this.x, this.y, this.width, this.height];
  }

  /**
   * @returns {[number, number]}
   */
  get xy() {
    return [this.x, this.y];
  }

   /**
   * @returns {[number, number]}
   */
  get wh() {
    return [this.width, this.height];
  }

  /* Center Points */

  get center() {
    return new Point((this.xmin + this.xmax) / 2, (this.ymin + this.ymax) / 2);
  }

  get topCenter() {
    const { center, top } = this;
    return new Point(center.x, top);
  }

  get bottomCenter() {
    const { center, bottom } = this;
    return new Point(center.x, bottom);
  }

  get leftCenter() {
    const { center, left } = this;
    return new Point(left, center.y);
  }

  get rightCenter() {
    const { center, right } = this;
    return new Point(right, center.y);
  }

  /**
   * @overload
   * @returns {Point}
   */

  /**
   * @overload
   * @param {'x' | 'y'} axis
   * @returns {number}
   */

  /**
   * @param {*} [axis]
   */
  min(axis) {
    if (axis === "x") {
      return this.xmin;
    } else if (axis === "y") {
      return this.ymin;
    } else if (axis === undefined) {
      return new Point(this.xmin, this.ymin);
    } else {
      throw new Error("Argument passed must be 'x' or 'y'");
    }
  }

  /**
   * @overload
   * @returns {Point}
   */

  /**
   * @overload
   * @param {'x' | 'y'} axis
   * @returns {number}
   */

  /**
   * @param {*} [axis]
   */
  max(axis) {
    if (axis === "x") {
      return this.xmax;
    } else if (axis === "y") {
      return this.ymax;
    } else if (axis === undefined) {
      return new Point(this.xmax, this.ymax);
    } else {
      throw new Error("Argument passed must be 'x' or 'y'");
    }
  }

  get 0() {
    return this.min();
  }

  get 1() {
    return this.max();
  }

  get length() {
    return 2;
  }

  [Symbol.iterator]() {
    return [
      new Point(this.xmin, this.ymin),
      new Point(this.xmax, this.ymax),
    ].values();
  }

  /**
   *
   * @param  {...number} args
   * @returns
   */
  offset(...args) {
    let dxmin, dymin, dxmax, dymax;
    const { xmin, ymin, xmax, ymax } = this;
    switch (args.length) {
      case 1:
        [dymax] = args;
        dxmax = dymax;
        dxmin = dymin = -dymax;
        break;
      case 2:
        [dxmax, dymax] = args;
        dxmin = -dxmax;
        dymin = -dymax;
        break;
      case 4:
        [dxmin, dymin, dxmax, dymax] = args;
        dxmin = -dxmin;
        dymin = -dymin;
        break;
      default:
        throw new Error("Expected 1, 2, or 4 arguments");
    }
    return new Box(xmin + dxmin, ymin + dymin, xmax + dxmax, ymax + dymax);
  }

  /**
   *
   * @param { 'x' | 'y'} axis
   * @param  {...number} args
   */
  split(axis, ...args) {
    let umin, umax, vmin, vmax, u0, u1;

    if (axis == "x") {
      [umin, vmin] = this.min();
      [umax, vmax] = this.max();
    } else if (axis == "y") {
      [vmin, umin] = this.min();
      [vmax, umax] = this.max();
    } else {
      throw new Error("Axis must be 'x' or 'y'");
    }

    return (function* splitGen() {
      u1 = umin;

      for (let i = 0; i <= args.length; i += 1) {
        const u = args[i] || umax;
        u0 = u1;
        u1 = u < 0 ? umax + u : Math.min(umax, umin + u);

        if (u1 < u0) {
          throw new Error("Resolved split points must increase monotonically");
        }

        const box = axis === "x" ? [u0, vmin, u1, vmax] : [vmin, u0, vmax, u1];

        yield new Box(...box);

        /* Split points beyond umax are ignored */
        if (u1 == umax) {
          break;
        }
      }
    })();
  }

  /**
   *
   * @param  {...number} args
   */
  *splitX(...args) {
    yield* this.split("x", ...args);
  }

  /**
   *
   * @param  {...number} args
   */
  *splitY(...args) {
    yield* this.split("y", ...args);
  }

  /**
   * @overload
   * @param {number} x
   * @param {number} y
   * @returns {Generator<Box>}
   */

  /**
   * @overload
   * @param {Array<number>} xs
   * @param {Array<number>} ys
   * @returns {Generator<Box>}
   */

  /**
   *
   * @param  {...any} args
   * @returns {Generator<Box>}
   */
  *splitXY(...args) {
    let [xs, ys] = args;
    xs = typeof xs === "number" ? [xs] : xs;
    ys = typeof ys === "number" ? [ys] : ys;

    const rows = this.splitY(...ys);

    for (const row of rows) {
      yield* row.splitX(...xs);
    }
  }

  /**
   * @param {number} n
   */
  *rows(n) {
    const { height, top, xmin, xmax } = this;
    const dy = height / n;

    let y = top;
    for (let i = 0; i < n; i += 1) {
      const ymin = y;
      const ymax = y + dy;
      yield new Box(xmin, ymin, xmax, ymax);
      y = ymax;
    }
  }

  /**
   * @param {number} n
   */
  *columns(n) {
    const { width, left, ymin, ymax } = this;
    const dx = width / n;

    let x = left;
    for (let i = 0; i < n; i += 1) {
      const xmin = x;
      const xmax = x + dx;
      yield new Box(xmin, ymin, xmax, ymax);
      x = xmax;
    }
  }

  /**
   *
   * @param {number} r
   * @param {number} c
   */
  *grid(r, c) {
    for (const row of this.rows(r)) {
      yield* row.columns(c);
    }
  }
}

export { Box, getZoomscale, pxy };
