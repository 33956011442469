import { compareMarks as compareAlphabeticMarks } from "../identifiers/mark.js";

let marksplitter;
try {
  const str = /[.\-\/\\]|(?<=[0-9])(?=[a-zA-Z])|(?<=[a-zA-Z])(?=[0-9])/
    .toString()
    .slice(1, -1);
  marksplitter = new RegExp(str);
} catch (e) {
  marksplitter = /[.\-\/\\]/;
}
const numtest = /^\d+$/;

function compareMarks(ao, bo) {
  const a = ao.trim();
  const b = bo.trim();

  const asplit = a.split(marksplitter);
  const bsplit = b.split(marksplitter);

  for (let i = 0; i < asplit.length; i++) {
    const aseg = asplit[i];
    const bseg = bsplit[i];

    if (aseg !== undefined && bseg !== undefined) {
      if (aseg === bseg) continue;

      if (numtest.test(aseg) && numtest.test(bseg)) {
        return parseInt(aseg, 10) - parseInt(bseg, 10);
      } else {
        return compareAlphabeticMarks(aseg, bseg);
      }
    } else if (aseg !== undefined) {
      return 1;
    } else if (bseg !== undefined) {
      return -1;
    }
  }
}

export const numeric = (a, b) => a - b;
export const markSort = (a, b) => compareMarks(a || "", b || "");
export const alphabetic = (a, b) => (a || "").localeCompare(b || "");
export const timestamp = (a, b) => new Date(a) - new Date(b);
export const dimtext = (a, b) => {
  const aIn = a ? a.convert("inches").quants[0].toPrimitive().value : 0;
  const bIn = b ? b.convert("inches").quants[0].toPrimitive().value : 0;
  return aIn - bIn;
};

export const sortFunctions = {
  created_at: timestamp,
  updated_at: timestamp,
  name: alphabetic,
  location: alphabetic,
  mark: markSort,
  width: dimtext,
  height: dimtext,
  quantity: numeric,
  seller_reference: alphabetic,
  buyer_reference: alphabetic,
  alphabetic,
  numeric,
  timestamp,
};

export function alphabeticMultiSort(a, b) {
  const av = a.company_name || a.name || a.email;
  const bv = b.company_name || b.name || b.email;
  return alphabetic(av, bv);
}
