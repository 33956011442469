function path(ctx, el) {
  const d = el.commands
    .map((c) => `${c.command} ${c.params.join(" ")}`)
    .join(" ");

  const attributes = { d };
  if (el.fill) {
    attributes.fill = el.fill;
  }

  if (el.stroke) {
    attributes.stroke = el.stroke;
    if (ctx.strokeScale) {
      attributes["stroke-width"] = el.lineWidth * ctx.strokeScale;
    } else {
      attributes["stroke-width"] = el.lineWidth;
      attributes["vector-effect"] = "non-scaling-stroke";
    }

    if (el.lineDash) {
      attributes["stroke-dasharray"] = el.lineDash.join(" ");
    }
  }

  ctx.entities.push({ tag: "path", attributes });
}

function mask(ctx, el) {}

function closeMask(ctx, el) {}

function text(ctx, el) {
  const fontSize = el.fontSize * el.annoScale;
  const anchor =
    {
      left: "start",
      center: "middle",
      right: "end",
    }[el.textAlign] || "start";

  const attributes = {
    fill: el.fill,
    "font-size": fontSize,
    "text-anchor": anchor,
    transform: `translate(${el.x} ${el.y}) scale(1 -1)`,
    style: `font-family: ${el.font}`,
  };

  ctx.entities.push({ tag: "text", content: el.text, attributes });
}

function image(ctx, el) {}

function circle(ctx, el) {
  const attributes = { cx: el.x, cy: el.y, r: el.r };

  if (el.fill) {
    attributes.fill = el.fill;
  }

  if (el.stroke) {
    attributes.stroke = el.stroke;
    attributes["stroke-width"] = el.lineWidth;
    if (ctx.strokeScale) {
      attributes["stroke-width"] = el.lineWidth * ctx.strokeScale;
    } else {
      attributes["stroke-width"] = el.lineWidth;
      attributes["vector-effect"] = "non-scaling-stroke";
    }

    if (el.lineDash) {
      attributes["stroke-dasharray"] = el.lineDash.join(" ");
    }
  }

  ctx.entities.push({ tag: "circle", attributes });
}

function measureText(ctx, text, styles) {
  const fs = styles.fontSize;
  return {
    width: fs * (text?.length || 0) * 0.6,
    actualBoundingBoxAscent: fs * (3 / 4),
    actualBoundingBoxDescent: fs * (1 / 4),
  };
}

function renderEntity(entity) {
  const attributes = Object.entries(entity.attributes)
    .map(([key, value]) => `${key}="${value}"`)
    .join(" ");

  if (entity.children) {
    const children = entity.children.map((e) => renderEntity(e));
    return `<${entity.tag} ${attributes}>${children.join("")}</${entity.tag}>`;
  } else if (entity.content) {
    return `<${entity.tag} ${attributes}>${entity.content}</${entity.tag}>`;
  } else {
    return `<${entity.tag} ${attributes} />`;
  }
}

function play(ctx, stack) {
  const ops = { path, mask, closeMask, text, image, circle };

  if (!ctx.entities) {
    ctx.entities = [];
  }

  stack.forEach((el) => ops[el.type](ctx, el));
  const viewBox = ctx.viewBox || "0 0 100 100";
  const width = ctx.width || "100%";
  const height = ctx.height || "100%";
  const xmlns = "http://www.w3.org/2000/svg";
  ctx.string = `<svg xmlns="${xmlns}" viewBox="${viewBox}" width="${width}" height="${height}">
  <g transform="scale(1 -1)">
    ${ctx.entities.map((e) => renderEntity(e)).join("")}
  </g>
  </svg>`;
}

export default { measureText, play };
