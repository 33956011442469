import { writable, derived } from "svelte/store";
import { sortableList } from "@local/extensions/collections/sortable-list.js";

const storedUi = JSON.parse(localStorage.getItem("ui")) || {};
const ui = {
  show_table: true,
  show_summary_splitscreen: false,
  table_left: false,
  show_right_panel: true,
  expand_right_panel: false,
  show_drafts: true,
  show_unsent: true,
  show_sent: true,
  group_by: "type_id",
  group_by_direction: "asc",
  sort_by: "created_at",
  sort_by_direction: "asc",
  group_collections_by: "none",
  group_collections_by_direction: "asc",
  sort_collections_by: "created_at",
  sort_collections_by_direction: "asc",
  setting_display_show_unused_types: true,
  setting_display_show_spanning_dims: true,
  setting_display_tn_badges: true,
  setting_display_tn_qty: true,
  setting_display_tn_dims: true,
  setting_display_tn_mark: true,
  setting_display_edge_offsets: true,
  my_rfqs_only: true,
  ...storedUi,
};
localStorage.setItem("ui", JSON.stringify(ui));

export function storedWritable(key, value, lsKey = "ui") {
  const { set, update, ...rest } = writable(value);

  return {
    ...rest,
    set: (v) => {
      set(v);
      ui[key] = v;
      localStorage.setItem(lsKey, JSON.stringify(ui));
    },
    update: (f) => {
      update((o) => {
        const v = f(o);
        ui[key] = v;
        localStorage.setItem(lsKey, JSON.stringify(ui));
      });
    },
  };
}

function createItemHt() {
  const { width } = window.screen;
  const defaultHt = width < 600 ? 110 : 200;
  const { subscribe, set, update } = writable(defaultHt);
  let fit = false;

  return {
    subscribe,
    zoomin: () => update((n) => Math.min(n * 1.3, 1012.5)),
    zoomout: () => update((n) => Math.max(n * (1 / 1.3), 85)),
    fit: (h) => {
      fit = true;
      if (h < 150) return set(110);
      const rows = width < 600 ? Math.floor(h / 110) : Math.floor(h / 143);
      return set(h / rows);
    },
    reset: () => set(143),
    hasBeenFit: () => fit,
  };
}

function createSelected() {
  const { set, update, ...rest } = writable({});

  return {
    ...rest,
    set,
    update,
    select: (...ids) => {
      const idObj = ids.reduce((o, id) => {
        o[id] = true;
        return o;
      }, {});

      update((o) => {
        return { ...o, ...idObj };
      });
    },
    selectOnly: (...ids) => {
      const idObj = ids.reduce((o, id) => {
        o[id] = true;
        return o;
      }, {});

      set(idObj);
    },
    deselect: (...ids) => {
      update((o) => {
        ids.forEach((id) => {
          delete o[id];
        });
        return o;
      });
    },
    toggle: (...ids) => {
      update((o) => {
        const newO = { ...o };
        ids.forEach((id) => {
          if (newO[id]) {
            delete newO[id];
          } else {
            newO[id] = true;
          }
        });
        return newO;
      });
    },
  };
}

// Store route for each tab
export const navHistory = writable({
  items: null,
  collections: null,
  types: null,
  fabrications: null,
  documents: null,
});

export const selectedItems = createSelected(); // selected items in item list view
export const selectedCollections = createSelected(); // selected collections in collection list view
export const selectedTypes = createSelected(); // selected types in type list view
export const selectedFabs = createSelected(); // selected fabs in fab list view
export const selectedDocuments = createSelected(); // selected documents in document list view
export const selectedMaterials = createSelected(); // selected materials in material list view
export const selectedSurfaces = createSelected(); // selected surfaces in surface list view
export const selectedMakeups = createSelected(); // selected makeups in material list view
export const selectedItemProds = createSelected(); // selected item productss in item product list view
export const selectedCategories = createSelected(); // selected categories in category list view
export const selectedEdges = createSelected(); // selected edges in edge list view
export const selectedFabrications = createSelected(); // selected fabrications in fabrication list view
export const currentDocid = writable(null); // current document id
export const currentSuppliers = writable(sortableList([])); // collection of current suppliers (for free users)
export const currentSupplierid = writable(null); // current supplier id (for free users)
export const makeupSupplierOptions = writable([]); // valid suppliers for type selection
export const makeupSupplier = writable(null); // current makeup supplier
export const selectedLocations = createSelected(); // selected locations in document view
export const selectedAnnotations = createSelected(); // selected annotations in document view
export const selectedType = writable(null); // selected type (at most one can be selected) in item list view
export const pathRoot = writable("");
export const currentTool = writable("select");
export const itemHt = createItemHt();
export const detailVisible = writable(false); // for small screens: is the detail panel on the home screen visible?
export const showTable = storedWritable("show_table", ui.show_table);
export const showSummarySplitscreen = storedWritable(
  "show_summary_splitscreen",
  ui.show_summary_splitscreen,
);
export const tableLeft = storedWritable("table_left", ui.table_left);
export const tempFeatureHole = writable(null);
export const tempFeatureEdgeFabrication = writable(null);
export const tempFeatureCornerFabrication = writable(null);
export const docLocationTab = writable("item");
export const clipboard = writable(null);
export const showDocFiles = writable(true);
export const showDocAnnotations = writable(true);
export const locationContext = writable(null);
export const showDrafts = storedWritable("show_drafts", ui.show_drafts);
export const showUnsent = storedWritable("show_unsent", ui.show_unsent);
export const showSent = storedWritable("show_sent", ui.show_sent);
export const showRightPanel = storedWritable(
  "show_right_panel",
  ui.show_right_panel,
);
export const expandRightPanel = storedWritable(
  "expand_right_panel",
  ui.expand_right_panel,
);
export const groupBy = storedWritable("group_by", ui.group_by);
export const groupByDirection = storedWritable(
  "group_by_direction",
  ui.group_by_direction,
);
export const sortBy = storedWritable("sort_by", ui.sort_by);
export const sortByDirection = storedWritable(
  "sort_by_direction",
  ui.sort_by_direction,
);
export const groupCollectionsBy = storedWritable(
  "group_collections_by",
  ui.group_collections_by,
);
export const groupCollectionsByDirection = storedWritable(
  "group_collections_by_direction",
  ui.group_collections_by_direction,
);
export const sortCollectionsBy = storedWritable(
  "sort_collections_by",
  ui.sort_collections_by,
);
export const sortCollectionsByDirection = storedWritable(
  "sort_collections_by_direction",
  ui.sort_collections_by_direction,
);
export const settingDisplayShowUnusedTypes = storedWritable(
  "setting_display_show_unused_types",
  ui.setting_display_show_unused_types,
);
export const settingDisplayShowSpanningDims = storedWritable(
  "setting_display_show_spanning_dims",
  ui.setting_display_show_spanning_dims,
);
export const settingDisplayTnBadges = storedWritable(
  "setting_display_tn_badges",
  ui.setting_display_tn_badges,
);
export const settingDisplayTnQty = storedWritable(
  "setting_display_tn_qty",
  ui.setting_display_tn_qty,
);
export const settingDisplayTnDims = storedWritable(
  "setting_display_tn_dims",
  ui.setting_display_tn_dims,
);
export const settingDisplayTnMark = storedWritable(
  "setting_display_tn_mark",
  ui.setting_display_tn_mark,
);
export const settingDisplayEdgeOffsets = storedWritable(
  "setting_display_edge_offsets",
  ui.setting_display_edge_offsets,
);
export const myRfqsOnly = storedWritable("my_rfqs_only", ui.my_rfqs_only);

// Represent all of the settings that require update of item list
// as a unique key
export const refreshItemList = derived(
  [
    settingDisplayTnBadges,
    settingDisplayTnDims,
    settingDisplayTnMark,
    settingDisplayTnQty,
    settingDisplayEdgeOffsets,
  ],
  (bools) => {
    return bools.map((b) => (b ? 1 : 0)).join("");
  },
);
